<template>
    <div class="dynamic w-1400">
        <!--通知公告-->
        <div class="gg w-1400">
            <div class="gg-title clearfix">
                <div class="fl"><i class="arrow-r"></i> 通知公告</div>
                <div class="more fr">
                    <router-link to="/List?infoType=249253697699909&type=通知公告">
                        更多<i class="el-icon-arrow-right"></i>
                    </router-link>
                </div>
            </div>
            <div class="gg-main mt-10">
                <el-row :gutter="30">
                    <el-col :span="14">
                        <div class="gg-banner">
                            <!-- <div class="gg-pic-big">
                                <router-link :to="`/detail?id=${calList[(Math.abs(transNum/205)+1)].id}&type=通知公告&oParams=${JSON.stringify(TZGGparams)}`">
                                    <el-image :src="calList[(Math.abs(transNum/205)+1)].photoUrls" style="width:100%;height:100%" fit="cover"></el-image>
                                </router-link>
                            </div>
                            <div class="cal-small">
                                <ul class="cal-small-list clearfix" :class="{istrans}" :style="`width:${205*calList.length}px;transform:translateX(${transNum}px)`">
                                    <li class="cal-smal-item fl" :class="{isSlace:(Math.abs(transNum/205)+1 === index)}" v-for="(item,index) in calList" :key="index +'cal'">
                                        <div class="star-pic">
                                            <el-image :src="item.photoUrls" style="width:100%;height:100%" fit="cover"></el-image>
                                        </div>
                                        <div class="ceng" v-show="!(Math.abs(transNum/205)+1 === index)"></div>
                                    </li>
                                </ul>
                                <div class="cal-btn prev" @click="prev"><i class="el-icon-arrow-left"></i></div>
                                <div class="cal-btn next" @click="next"><i class="el-icon-arrow-right"></i></div>
                            </div> -->
                            <div class="gg-vitem">
                                <div class="videobox">
                                    <video class="videotag" :src="video1.videoUrl" :poster="video1.photoUrls" controls width="100%" height="295px"></video>
                                </div>
                                <div class="gg-info">
                                    {{video1.introduction}}
                                </div>
                            </div>
                            <div class="gg-vitem">
                                <div class="gg-info">
                                    {{video2.introduction}}
                                </div>
                                <div class="videobox">
                                    <video class="videotag" :src="video2.videoUrl" :poster="video2.photoUrls" controls width="100%" height="295px"></video>
                                </div>
                            </div>
                        </div>
                    </el-col>
                    <el-col :span="10">
                        <div class="gg-list">
                            <ul>
                                <li class="gg-item" v-for="item in TZGGdata" :key="item.id" @click="goDetail(item.id,'通知公告',JSON.stringify(TZGGparams))">
                                    <div class="gg-text">{{item.title.substr(0,18)}}</div>
                                    <div class="gg-time">{{item.releaseDate&&item.releaseDate.substr(0,10)}}</div>
                                </li>
                            </ul>
                        </div>
                    </el-col>
                </el-row>
            </div>
        </div>    
        <!--行业动态-->
        <div class="hydt w-1400">
            <div class="gg-title clearfix">
                <div class="fl"><i class="arrow-r"></i> 行业动态</div>
                <div class="more fr">
                    <router-link to="/List?infoType=249253844123717&type=行业动态">
                        更多<i class="el-icon-arrow-right"></i>
                    </router-link>
                </div>
            </div>
            <div class="hydt-main mt-10">
                <el-row :gutter="30">
                    <el-col :span="15">
                        <div class="htyt-big">
                            <div class="big-pic">
                                <el-image :src="HYDTdata[0].photoUrls" style="width:100%;height:100%" fit="cover" @click="goDetail(HYDTdata[0].id,'行业动态',JSON.stringify(HYDTparams))"></el-image>
                            </div>
                            <div class="big-title">{{HYDTdata[0].title}}</div>
                        </div>
                    </el-col>
                    <el-col :span="9">
                        <div v-for="item in HYDTdata.slice(1,3)" :key="item.id">
                            <div class="sm-pic"  @click="goDetail(item.id,'行业动态',JSON.stringify(HYDTparams))">
                                <el-image :src="item.photoUrls" style="width:100%;height:100%" fit="cover"></el-image>
                            </div>
                            <div class="sm-title ellipsis">{{item.title}}</div>
                        </div>
                    </el-col>
                </el-row>
                <el-row :gutter="25">
                    <el-col :span="6" v-for="item in HYDTdata.slice(3,7)" :key="item.id">
                        <div class="hydt-2f">
                            <div class="hydt-2f-pic" @click="goDetail(item.id,'行业动态',JSON.stringify(HYDTparams))">
                                <el-image :src="item.photoUrls" style="width:100%;height:100%" fit="cover"></el-image>
                            </div>
                            <div class="sm-title ellipsis">{{item.title}}</div>
                        </div>
                    </el-col>
                </el-row>
            </div>
        </div>
        <!-- 价格监测 -->
        <div class="hydt w-1400">
            <div class="gg-title clearfix">
                <div class="fl"><i class="arrow-r"></i> 价格监测</div>
                <div class="more fr">
                    <router-link to="/List?infoType=314985509285957&type=价格监测">
                        更多<i class="el-icon-arrow-right"></i>
                    </router-link>
                </div>
            </div>
            <el-row :gutter="25" class="mt-10">
                <el-col :span="6" v-for="item in JGJCdata" :key="item.id">
                    <div class="hydt-2f">
                        <div class="hydt-2f-pic" @click="goDetail(item.id,'价格监测',JSON.stringify(JGJCparams))">
                            <el-image :src="item.photoUrls" style="width:100%;height:100%" fit="cover"></el-image>
                        </div>
                        <div class="sm-title jg">{{item.title}}</div>
                    </div>
                </el-col>
            </el-row>
        </div>
    </div>
</template>
<script>
import {GetList} from '@/api/other.js'
import { GetVideoInfoPage } from "@/api/xiangningqing";
export default {
    data(){
        return{
            transNum:0,
            calList:[{},{},{},{},{}],
            istrans:true,
            actimg:'',
            TZGGparams:{//通知公告
                currentPage: 1,
                filter: {infoType:249253697699909,},
                pageSize: 16,
            },
            TZGGdata:[],
            HYDTparams:{//行业动态
                currentPage: 1,
                filter: {infoType:249253844123717,},
                pageSize: 8,
            },
            HYDTdata:[{}],
            JGJCparams:{//价格监测
                currentPage: 1,
                filter: {infoType:314985509285957,},
                pageSize: 8,
            },
            JGJCdata:[{}],
            videoParams1:{
                currentPage: 1,
                filter: {type:"通知公告",videoName:"西宁市握稳乡村振兴“接力棒”  跑出三农发展“加速度”"},
                pageSize: 1,
            },
            videoParams2:{
                currentPage: 1,
                filter: {type:"通知公告",videoName:"河湟田园"},
                pageSize: 1,
            },
            video1:{},
            video2:{},
        }
    },
    methods:{
        // getVideos(params){
        //     GetVideoInfoPage()
        // },
        next(){
            console.log(this.transNum,-this.calList.length*205)
            if(this.transNum === -this.calList.length*205/2){
                this.transNum = 0
                this.istrans = false;
                setTimeout(() => {
                    this.istrans = true;
                    this.transNum -= 205
                    // this.actimg = this.imgdata[this.activeNum].img
                }, 1);
            }else{
                this.transNum -= 205
                // this.actimg = this.imgdata[this.activeNum].img
            }
        },
        prev(){
            if(this.transNum === 0){
                this.transNum = -this.calList.length*205/2
                this.istrans = false;
                setTimeout(() => {
                    this.istrans = true;
                    this.transNum += 205
                    // this.actimg = this.imgdata[this.activeNum].img
                }, 1);
            }else{
                this.transNum += 205
                // this.actimg = this.imgdata[this.activeNum].img
            }
        },
        getTZGGdata(){
            GetList(this.TZGGparams).then(res=>{
                this.TZGGdata = res.data.data.list;
                this.calList = res.data.data.list.slice(0,5);
                this.calList =this.calList.concat(this.calList)//临时的 该用于获取数据后
            })
        },
        getHYDTdata(){
            GetList(this.HYDTparams).then(res=>{
                this.HYDTdata = res.data.data.list;
            })
        },
        getJGJCdata(){
            GetList(this.JGJCparams).then(res=>{
                this.JGJCdata = res.data.data.list;
            })
        },
        goDetail(id,type,oParams){
            this.$router.push({path:'/detail',query:{id,type,oParams}})
        },
    },
    mounted(){
        this.getTZGGdata();
        this.getHYDTdata();
        this.getJGJCdata();
        GetVideoInfoPage(this.videoParams1).then(res=>{
            this.video1 = res.data.data.list[0]
        })
        GetVideoInfoPage(this.videoParams2).then(res=>{
            this.video2 = res.data.data.list[0]
        })
    }
}
</script>
<style scoped>
.gg-vitem{
    display: flex;
    height: 295px;
}
.gg-info{
    width: calc(100% - 400px);
    height: 100%;
    padding: 30px 10px;
    box-sizing: border-box;
    text-indent: 20px;
    font-size: 24px;
    line-height: 40px;
    display: flex;
    font-family: '黑体';
    align-items: center;
    background: #f6f6f6;
    writing-mode:vertical-rl
}
.video-box{
    width: 400px;
    height: 100%;
    background-color: #ccc;
}
.hydt-2f-pic{
    height: 195px;
}
.hydt-2f{
    margin-bottom: 12px;
}
.sm-pic{
    height: 260px;
}
.big-title,.sm-title{
    line-height: 40px;
    font-size: 18px;
    text-align: center;
}
.sm-title.jg{
    line-height: 30px;
    font-size: 16px;
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    background: #eee;
    text-align: left;
    text-indent: 22px;
    padding: 0 8px;
}
.big-pic{
    height: 560px;
}
.htyt-big{
    height: 600px;
}
.gg-item:hover{
    color: #0e6ac1;
    cursor: pointer;
}
.gg-item{
    line-height: 36.25px;
    font-size: 16px;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px dashed #eee;
}
.gg-list{
    padding: 10px;
    border: 1px solid #E4E7ED;
    border-radius: 3px;
    height: 600px;
    overflow: hidden;
}
.gg-banner{
    position: relative;
    height: 600px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: hidden;
}
.gg-pic-big{
    height: 600px;
}
.isSlace{
    transform: scale(1.2);
    border: 2px solid #fff;
    z-index: 20;
}
.isSlace .star-info{
    background: #f37b1c;
    color: #fff;
}
.istrans{
    transition: transform 1.2s;
}
.prev{
    left: 0px;
}
.next{
    right: 0;
}
.star-pic{
    height: 120px;
}
.cal-btn{
    width: 30px;
    height: 60px;
    line-height: 60px;
    font-size: 30px;
    font-weight: 700;
    position: absolute;
    top: 50%;
    border-radius: 3px;
    transform: translateY(-50%);
    background: rgba(255, 255, 255, .4);
    z-index: 20;
}
.cal-btn:hover{
    background: rgba(255, 255, 255, 1);
}
.ceng{
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, .4);
    position: absolute;
    top: 0;
    left: 0;
}
.cal-smal-item{
    width: 190px;
    height: 120px;
    overflow: hidden;
    margin-right: 15px;
    position: relative;
    transition: transform 1.2s;
    box-sizing: border-box;
}
.cal-small{
    height: 150px;
    padding-top: 15px;
    width: 600px;
    overflow: hidden;
    position: absolute;
    left: 50%;
    bottom: 20px;
    transform: translateX(-50%);
}
.gg-title .arrow-r{
    display: inline-block;
    width: 20px;
    height: 20px;
    background: url(../../assets/img/right2.png) no-repeat;
    background-size: 100% 100%;
    transform: translateY(2px);
}
.gg-title{
    height: 42px;
    line-height: 42px;
    font-size: 20px;
    font-weight: 600;
    /* border-bottom: 1px solid #999; */
}
.dynamic{
    font-family: '微软雅黑';
}
</style>